






import {Component, Prop, Vue} from 'vue-property-decorator';
// import LoginLayout from '@/components/auth/login/LoginLayout.vue';
const LoginLayout = () => import('@/components/auth/login/LoginLayout.vue')
// import LoginForm from '@/components/auth/login/LoginForm.vue';
const LoginForm = () => import('@/components/auth/login/LoginForm.vue')
@Component({
  components: { LoginForm, LoginLayout },
})
export default class Login extends Vue {
  @Prop({ required: false })
  redirect!: string | null;


  get isAuthenticated() {
    return this.$store.getters.isAuthenticated;
  }

  loggedIn() {
    if (this.redirect) {
      this.$router.push(this.redirect);
    } else {
      this.$router.push({name: 'dashboard'});
    }
  }
  mounted() {
    if(this.isAuthenticated) {
      this.$router.push({name: 'dashboard'});
    }
  }
}
